import React from 'react';

const LazySingInRegister = React.lazy(() => import('../views/sign-in-register/sign-in-register'));
const SingInRegister = (props) => (
    <React.Suspense fallback={"Cargando..."}>
        <LazySingInRegister {...props} />
    </React.Suspense>
)

const LazyCompleteRegistration = React.lazy(() => import("../views/complete-registration/complete-registration"));
const CompleteRegistration = (props) => (
    <React.Suspense fallback={"Cargando..."}>
        <LazyCompleteRegistration {...props} />
    </React.Suspense>
)

const LazyApp = React.lazy(() => import("../views/app/app"));
const App = (props) => (
    <React.Suspense fallback={"Cargando..."}>
        <LazyApp {...props} />
    </React.Suspense>
)

const mainRoutes = [
    { isPrivate: true, path: '/app/*', element: <App /> },
    { isPrivate: true, path: '/complete-registration', element: <CompleteRegistration /> },
    { isPrivate: false, path: '/*', element: <SingInRegister /> },
];

export default mainRoutes;