import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from 'redux-thunk'

import registerReducers from "./reducers/register.reducers"
import loginReducers from "./reducers/login.reducers"
import appReducers from "./reducers/app.reducers"

const mainReducer = combineReducers({
    register: registerReducers,
    login: loginReducers,
    app: appReducers
})

export default createStore(mainReducer, applyMiddleware(thunk))