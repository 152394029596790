import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import mainRoute from "./routes/main.routes";
import PrivateRoute from "./components/private-route/private-route";
import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './redux'
import { SnackbarProvider } from 'notistack'

const theme = createTheme({
  palette: {
    primary: {
      light: '#5ed9d9',
      main: "#16a7a8",
      dark: '#007779',
      contrastText: "#ffffff"
    },
    secondary: {
      light: '#4c9cbc',
      main: "#006e8c",
      dark: '#00435f',
    },
    black: {
      light: "#0000007d",
      main: "#000000b3",
      dark: "#000000",
      contrastText: "#ffffff"
    },
    background: {
      default: '#f7f6f6',
    }
  },
});

function App() {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              {
                mainRoute.map(
                  ({ isPrivate, element, ...rest }, key) => (
                    isPrivate ?
                      <Route key={key} element={<PrivateRoute>{element}</PrivateRoute>} {...rest} /> :
                      <Route key={key} element={element} {...rest} />
                  )
                )
              }
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
