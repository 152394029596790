export const registerConstants = {
    LOADING: 'LOADING_REGISTER',
    ERROR: 'ERROR_REGISTER',
    GENERATE_CODE: 'GENERATE_CODE_REGISTER',
    STEP: 'STEP_REGISTER',
    REGISTER: 'REGISTER_REGISTER',
    CLEAR: 'CLEAR_REGISTER',
    GET_PROFILE: 'GET_PROFILE',
    SET_PROFILE: 'SET_PROFILE',
    GET_PAYMENT: 'GET_PAYMENT',
    SET_PAYMENT: 'SET_PAYMENT',
    GET_COUNTRIES: 'GET_COUNTRIES_REGISTER',
    GET_SERVICES: 'GET_SERVICES_REGISTER',
    COMPLETE: 'COMPLETE_RGISTER'
}