
import { localStorageCluster } from "../../config";
import { loginConstants } from "../constants";
const { LOADING, ERROR, GENERATE_CODE, LOGIN, CLEAR } = loginConstants;

const initialState = {
    loading: false,
    error: null,
    success: false,
    codeGenerated: false,
    step: 1,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload,
            }
        case ERROR:
            return {
                ...state,
                error: payload,
            }
        case GENERATE_CODE: {
            return {
                ...state,
                codeGenerated: payload,
                step: payload ? 2 : 1
            }
        }
        case LOGIN: {
            localStorage.setItem(`${localStorageCluster}.user`, JSON.stringify(payload.user));
            localStorage.setItem(`${localStorageCluster}.terms_and_conditions`, JSON.stringify(payload.terms_and_conditions));
            localStorage.setItem(`${localStorageCluster}.token`, payload.api_token);

            return {
                ...state,
                step: 3
            }
        }
        case CLEAR: {
            return {
                ...state,
                codeGenerated: false,
                error: null,
                step: 1
            }
        } 
        default:
            return {
                ...state
            }
    }
}