
import { localStorageCluster } from "../../config";
import { registerConstants } from "../constants";
const { LOADING, ERROR, GENERATE_CODE, REGISTER, GET_PAYMENT, SET_PAYMENT, CLEAR, SET_PROFILE, GET_PROFILE, GET_COUNTRIES, GET_SERVICES, COMPLETE } = registerConstants;

const initialState = {
    loading: false,
    error: null,
    success: false,
    codeGenerated: false,
    step: 1,
    data: null,
    countries: [],
    services: [],
    profile: {},
    payment: {}
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload,
            }
        case ERROR:
            return {
                ...state,
                error: payload,
            }
        case GENERATE_CODE:
            return {
                ...state,
                codeGenerated: payload,
                step: payload ? 2 : 1
            }
        case REGISTER:
            localStorage.setItem(`${localStorageCluster}.user`, JSON.stringify(payload.user));
            localStorage.setItem(`${localStorageCluster}.token`, payload.api_token);
            return {
                ...state,
                step: 3
            }
        case COMPLETE:
            localStorage.setItem(`${localStorageCluster}.user`, JSON.stringify(payload));
            return {
                ...state,
                success: true,
                step: 4
            }
        case SET_PROFILE:
            return {
                ...state,
                success: true,
                data: payload
            }
        case SET_PAYMENT:
            return {
                ...state,
                success: true,
                data: payload
            }
        case CLEAR:
            return {
                ...state,
                codeGenerated: false,
                error: null,
                step: 1
            }
        case GET_COUNTRIES:
            return {
                ...state,
                countries: payload
            }
        case GET_SERVICES:
            return {
                ...state,
                services: payload
            }
        case GET_PROFILE:
            return {
                ...state,
                profile: payload
            }
        case GET_PAYMENT:
            return {
                ...state,
                payment: payload
            }
        default:
            return {
                ...state
            }
    }
}