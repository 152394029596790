import { appConstants } from "../constants";
const { LOADING, ERROR, ACTIVE_STAGE, REGISTRATION_STAGE, GET_TERMS_CONDITIONS, ACCEPT_TERMS_AND_CONDITIONS } = appConstants;

const initialState = {
    loading: false,
    error: null,
    stage: null,
    termsConditions: null,
    acceptTermsAndConditions: false,
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload,
            }
        case ERROR:
            return {
                ...state,
                error: payload,
            }
        case ACTIVE_STAGE: {
            return {
                ...state,
                stage: payload,
            }
        }
        case REGISTRATION_STAGE: {
            return {
                ...state,
                stage: payload,
            }
        }
        case ACCEPT_TERMS_AND_CONDITIONS: {
            return {
                ...state,
                acceptTermsAndConditions: payload,
            }
        }
        case GET_TERMS_CONDITIONS: {
            return {
                ...state,
                termsConditions: payload,
            }
        }
        default:
            return {
                ...state
            }
    }
}